
import Vue from "vue";

export default Vue.extend({
  name: "AppMenuComponent",
  computed: {
    Nombre(): string {
      return this.$store.getters["oidcStore/oidcUser"].nombre;
    },
  },
});
